import React from "react";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { Typography, makeStyles, Container } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    marginTop: "7rem",
    marginBottom: "2rem",
  },
  spaceAfter: {
    marginBottom: "2rem",
  },
});

const Gutscheinbedingungen = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography variant="h2" align="center" gutterBottom>
            Gutscheinbedingungen im Überblick
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            M3E Gutscheinportal
          </Typography>
          <Typography variant="h3" align="center" gutterBottom>
            THG-Quotenservice
          </Typography>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.spaceAfter}
          >
            Stand: 12.07.2024
          </Typography>
          <Typography color="primary" variant="h5" gutterBottom>
            1. AVILOO GmbH – Gutscheinbedingungen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gutscheinwert</b>: 99 €
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gültigkeitsdauer</b>: ab Datum des Erhalts drei Jahre
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlösbarkeit</b>: Der Gutschein gilt nur für das Produkt und hat
            einen Wert von 99 €.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlöse Bedingungen</b>: kein Mindestbestellwert
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht übertragbar</b>: Pro Kunde und pro genehmigte THG-Quote ist
            nur ein Gutschein einlösbar.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht kombinierbar</b>: Der Gutschein ist nicht auf andere
            Produkte anrechenbar
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Keine Barauszahlung</b>: keine Auszahlung möglich
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.spaceAfter}
          >
            <b>Rückgabe und Umtausch</b>: Umtausch ausgeschlossen, gesetzliche
            Garantie auf Produkte vorhanden.
          </Typography>
          <Typography color="primary" variant="h5" gutterBottom>
            2. Charge & Co GmbH – Gutscheinbedingungen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gutscheinwert</b>: 100 €
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gültigkeitsdauer</b>: ab Datum des Erhalts drei Jahre
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlösbarkeit</b>: Der Gutschein kann ab einem Einkaufswert von
            100 EUR eingelöst werden.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlöse Bedingungen</b>: kein Mindestbestellwert
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht übertragbar</b>: Pro Kunde und pro genehmigte THG-Quote ist
            nur ein Gutschein (nur einmal) einlösbar.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht kombinierbar</b>: Der Gutschein ist nicht kombinierbar mit
            anderen Rabattaktionen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Keine Barauszahlung</b>: keine Auszahlung möglich
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.spaceAfter}
          >
            <b>Rückgabe und Umtausch</b>: Umtausch ausgeschlossen, gesetzliche
            Garantie auf Produkte vorhanden.
          </Typography>
          <Typography color="primary" variant="h5" gutterBottom>
            3. GREEN SHIRTS GmbH – Gutscheinbedingungen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gutscheinwert</b>: 100 €
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gültigkeitsdauer</b>: ab Datum des Erhalts drei Jahre
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlösbarkeit</b>: Der Gutschein kann ab einem Einkaufswert von
            100 EUR eingelöst werden.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlöse Bedingungen</b>: Mindestbestellwert 100 € & Artikel im
            Sale (Abverkauf) sind ausgeschlossen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht übertragbar</b>: Pro Kunde und pro genehmigte THG-Quote ist
            nur ein Gutschein (nur einmal) einlösbar.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht kombinierbar</b>: Der Gutschein ist nicht kombinierbar mit
            anderen Rabattaktionen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Keine Barauszahlung</b>: keine Auszahlung möglich
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.spaceAfter}
          >
            <b>Rückgabe und Umtausch</b>: Umtausch ausgeschlossen, gesetzliche
            Garantie auf Produkte vorhanden.
          </Typography>
          <Typography color="primary" variant="h5" gutterBottom>
            4. GoodBuy GmbH – Gutscheinbedingungen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gutscheinwert</b>: 100 €
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gültigkeitsdauer</b>: ab Datum des Erhalts drei Jahre
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlösbarkeit</b>: Der Gutschein kann ab einem Einkaufswert von
            100 EUR eingelöst werden.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlöse Bedingungen</b>: kein Mindestbestellwert
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht übertragbar</b>: Pro Kunde und pro genehmigte THG-Quote ist
            nur ein Gutschein (nur einmal) einlösbar.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht kombinierbar</b>: Der Gutschein ist nicht kombinierbar mit
            anderen Rabattaktionen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Keine Barauszahlung</b>: keine Auszahlung möglich
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.spaceAfter}
          >
            <b>Rückgabe und Umtausch</b>: Umtausch ausgeschlossen, gesetzliche
            Garantie auf Produkte vorhanden.
          </Typography>
          <Typography color="primary" variant="h5" gutterBottom>
            5. Paper & Sons – Gutscheinbedingungen
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gutscheinwert</b>: 100 €
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Gültigkeitsdauer</b>: ab Datum des Erhalts drei Jahre
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlösbarkeit</b>: Der Gutschein kann ab einem Einkaufswert von
            100 EUR eingelöst werden.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Einlöse Bedingungen</b>: kein Mindestbestellwert
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht übertragbar</b>: Pro Kunde und pro genehmigte THG-Quote ist
            nur ein Gutschein (nur einmal) einlösbar.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Nicht kombinierbar</b>: Der Gutschein ist nicht kombinierbar mit
            anderen Rabattaktionen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Keine Barauszahlung</b>: keine Auszahlung möglich
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.spaceAfter}
          >
            <b>Rückgabe und Umtausch</b>: Umtausch ausgeschlossen, gesetzliche
            Garantie auf Produkte vorhanden.
          </Typography>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Gutscheinbedingungen;
